<script setup>
/*
*/
import {ref, watch, computed, reactive, onMounted} from 'vue'

import { useRoute, useRouter } from 'vue-router'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const course_data = reactive({
    title: "Title",
    summary: "A short summary of the course that is shown in the Lesson list",
    difficulty: 0,

    lessons: [],
});

const lessons_data = reactive({

});

const extra_data = reactive({
    new_lesson_id: "",
});

var course_id = undefined;

onMounted(async () => {
    course_id = route.params.course_id;
    await get_course(course_id);
    for(let lesson_id of course_data.lessons){
        get_lesson(lesson_id);
    }
});

async function get_course(course_id){
    console.log("Loading course", course_id);
    if(course_id == null || course_id.length < 5){
        return;
    }

    const response = await fetch(app_server + `/lesson/course/${ course_id }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },

    });
    const data = await response.json();
    Object.assign(course_data, data.course);

    return data.course;
}

async function save_course(){
    var url = "/admin/lesson/course/";
    if(course_data.course_id != null){
        url += course_data.course_id + "/";
    }
    
    console.log("SAVE:", url, course_data);

    const response = await fetch(app_server + url, {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(course_data),

    });
    const data = await response.json();
    Object.assign(course_data, data.course);
    console.log("Course", data);

    router.replace({name: "admin-course-detail", params: {"course_id": course_data.course_id}});

    return data.course;
}

async function get_lesson(lesson_id){
    if(lessons_data[lesson_id] != null){
        return;
    }
    const response = await fetch(app_server + `/lesson/${ lesson_id }/`, {
        method: "GET",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },

    });
    const data = await response.json();
    lessons_data[lesson_id] = data.lesson;
}

function add_lesson(lesson_id){
    if(lesson_id == null || lesson_id.length == 0){
        return;
    }
    course_data.lessons.push(lesson_id);
    get_lesson(lesson_id);

    extra_data.new_lesson_id = "";
}

function move(lesson_id, places){
    const index = course_data.lessons.findIndex((x) => x == lesson_id);
    course_data.lessons.splice(index, 1);
    course_data.lessons.splice(index+places, 0, lesson_id);
}

function remove(lesson_id){
    const index = course_data.lessons.findIndex((x) => x == lesson_id);
    course_data.lessons.splice(index, 1);
}

</script>

<template>
    <div class="container flex flex-col gap-y-4 mx-auto max-w-prose">
        <h1 class="text-2xl">Edit Course</h1>
        
        <input v-if="!course_id" v-model="course_data.title" />
        <div v-else>
            <h1 class="text-2xl">{{ course_data.title }}</h1>
            <span class="font-thin"> {{ course_data.slug }}</span>
        </div>
        <select v-model="course_data.status">
            <option value="hidden">Hidden</option>
            <option value="listed">Listed</option>
        </select>

        <textarea v-model="course_data.summary"></textarea>

        <div>
            <div v-for="lesson_id in course_data.lessons">
                <span v-if="lessons_data[lesson_id] == null">
                    {{ lesson_id }}
                </span>
                <div v-else class="border flex flex-col p-2 gap-y-2">
                    <h1 class="text-xl">{{ lessons_data[lesson_id].title }}</h1>
                    <span class="">{{ lessons_data[lesson_id].author.username }}</span>
                    <div>{{ lessons_data[lesson_id].summary }}</div>
                    <div>
                        <button class="btn btn-yellow" @click="remove(lesson_id)">Remove</button>
                        <button class="btn btn-blue" @click="move(lesson_id, 1)">Up</button>
                        <button class="btn btn-blue" @click="move(lesson_id, -1)">Down</button>
                    </div>
                </div>
            </div>
            <div>
                <input placeholder="Lesson ID" v-model="extra_data.new_lesson_id" />
                <button class="btn btn-blue"
                        :disabled="extra_data.new_lesson_id == null || extra_data.new_lesson_id.length == 0"
                    @click="add_lesson(extra_data.new_lesson_id)">
                    Add Lesson
                </button>
            </div>
        </div>
        <button class="btn btn-blue" @click="save_course()">
            Save
        </button>



        
    </div>
</template>

<style scoped>
.game{
    height:100vh;
    height:100svh;
    width:100vw;
}
</style>



export const time_controls = {
    match: [
        ["Casual", "120|20|0"],
        ["Normal", "60|10|0"],
        ["Speed", "20|8|0"],
        ["Bullet", "30|2|0"],
        ["No Clock", "-1|0|0"],
        // ["Custom", ""],
    ],
    unlimited: [
        ["Casual", "120|10|2"],
        ["Normal", "60|8|1"],
        ["Speed", "20|6|0.5"],
        ["Bullet", "30|1|0.5"],
        ["No Clock", "-1|0|0"],
        // ["Custom", ""],
    ],
    daily: [
        ["12h", "43200|43200|3600"],
        ["24h", "86400|86400|3600"],
        ["48h", "86400|172800|3600"],
    ],
};

export function time_control_to_text(tc){
    const parts = tc.split("|");

    var text = `Reserve: ${parts[0]}s / point. Delay: ${parts[1]}s / move.`;
    if(parseFloat(parts[2]) > 0){
        text += ` Increment: ${parts[2]}s / move.`;
    }
    if(parseFloat(parts[0]) < 0){
        text = "The clock will be disabled for the match.";
    }
    return text;
}

export function timecontrol_to_config(tc){
    const parts = tc.split("|");
    if(parts.length != 3){
        return null;
    }

    return {
        reserve: parts[0],
        delay: parts[1],
        increment: parts[2],
    }
}

<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

const extra_data = reactive({
    user:{},
    open_account_details:false,
    total_nrof_users:0,
    offset:0,
    status:"active",
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const course_list = reactive([]);
onMounted(() => {
    load_course_list();
});

async function load_course_list(){
    var url = app_server + "/admin/lesson/course/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });

    const data = await response.json();
    course_list.length = 0;// empty the users list

    if(data.courses){
        course_list.push(...data.courses);
        //extra_data.total_nrof_users = data.total_nrof_users;
    }
    console.log(data);
}

async function remove_course(course_id){
    const response = await fetch(app_server + `/admin/course/${course_id}/delete/`, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({}),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_course_list();
}

function next_page(){
    extra_data.offset += course_list.length;
    load_course_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - course_list.length);
    load_course_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">

    <router-link :to="{name: 'admin-course-detail'}">
        <button class="btn btn-blue">
            Create Course 
        </button>
    </router-link>
<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Title</th> 
        <th scope="col" class="sticky top-0 z-10">Status</th> 
        <th scope="col" class="sticky top-0 z-10">Published</th> 
        <th scope="col" class="sticky top-0 z-10">Difficulty</th> 
        <th scope="col" class="sticky top-0 z-10"># Lessons</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="course_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Courses
    </td>
    </tr>
    <tr v-for="course, index in course_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'admin-course-detail', params: {course_id: course.course_id}}">
                {{ course.title }}
            </router-link>
        </td>
        <td class="text-left">{{ course.status }}</td>
        <td class="text-left">{{ (new Date(course.publish_time)).toLocaleString() }}</td>
        <td class="text-right">{{ course.difficulty }}</td>
        <td class="text-right">{{ course.lessons.length }}</td>
        <td>
            <button class="btn" @click="remove_course(course.course_id)">Remove</button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>


<script setup>
import Point from './Point.vue'
import Dice from './Dice.vue'

import Cube from './Cube.vue'
import Score from './Score.vue'
import Clock from './Clock.vue'
import Prompt from './Prompt.vue'
import Avatar from './Avatar.vue'
import UserName from './UserName.vue'

import {ref, nextTick, computed, reactive, watch, onMounted, onBeforeUnmount, onUpdated} from 'vue'
import {BoardState} from '../assets/js/board.js'
import {StateMachine} from '../assets/js/statemachine.js'

import LeaderLine from 'vue3-leaderline'
import AutorollIcon from './AutorollIcon.vue'
import { FireIcon, FlagIcon, SparklesIcon, ChatBubbleLeftIcon, ChatBubbleLeftEllipsisIcon} from '@heroicons/vue/24/solid'

import checkerAudio from '../assets/audio/checker.m4a'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useGameSettingStore} from '@/stores/gamesettingstore.js'
const gameSettingStore = useGameSettingStore();

const checker_sound = new Howl({
    src: [checkerAudio],
    volume: 1.0,
});

/* 
The game state is communicated through the positionString (or BoardState)
*/

const props = defineProps({
    boardID: { // Used if multiple boards are shown in the same view
        type: String,
        default: "",
    },
    positionString: { // 123456789abcdefhijklmno
        type: String,
        default: "",
    },
    player_color: { // The color of the player
        type: String,
        default: null,
    },
    all_players_connected: {
        type: Boolean,
        default: false,
    },
    show_resign: {
        type: Boolean,
        default: false,
    },
    show_chat: {
        type: Boolean,
        default: false,
    },
    show_autoroll: {
        type: Boolean,
        default: false,
    },
    can_do_actions: {
        type: Boolean,
        default: true,
    },
    show_pip_numbers: {
        type: Boolean,
        default: false,
    },
    show_pip_count: {
        type: Boolean,
        default: true,
    },
    differences: {
        type: Object,
        default: {},
    },
    clock_data: {
        type: Object,
        default: {},
    },
    board_message: {
        type: String,
        default: "",
    },
    match_info: {
        type: Object,
        default: {},
    },
    arrows: {
        type: Array,
        default: [],
    },
    premoves: {
        type: Array,
        default: [],
    },
    chat_status: {
        type: String,
        default: "new",
    },
    direction: {
        type: String,
        default: "CW"
    },
    last_game: {
        type: Boolean,
        default: false,
    },
    autoroll: {
        type: Boolean,
        default: false,
    },
    edit_mode: {
        type: Boolean,
        default: false,
    },
    theme: {
        type: Object,
        default: null,
    }
});

const emit = defineEmits([
    'move-end',
    'premove',
    'chat',
    'togglechat',
    'autoroll',
]);

let board = new BoardState();
let board_ref = ref(board);
let state_machine = new StateMachine();
let animating = 0; // The number of checker animations currently running
let board_w = 100;

const state = reactive({
    points: [],
    dice: [],
    cube: null,
    color: undefined,
    move_id: null,
});

const extra_data = reactive({
    moves: [],
    show_resign: false,
    isMounted: false,
    arrows: [],
    drawn_arrows: [], 
    point_highlights: {},
    refresh: 0,
    direction: props.direction,
    animation_duration: 0,
    show_pip_numbers: null,

    show_roll_double: false,
    current_edit_color: "W",
});

const chat_data = reactive({
    new_message: false,
});

watch(() => props.arrows, async (new_arrows, old_arrows) => {
    update_arrows();
});

watch(() => props.theme, async (new_theme, old_theme) => {
    nextTick( () => {
    });
});

watch(() => props.positionString, async (new_positionstring, old_positionstring) => {
    updatePosition(new_positionstring);    
    extra_data.moves = [];
    update_arrows();
});

watch(() => props.premoves, async (premoves, old_premoves) => {
    handle_premoves(premoves);
});

watch(extra_data.drawn_arrows, async (newArrows, oldArrows) => {
    update_arrows();
});

onMounted(() => {
    userStore.loadUser().then( () => {
        userStore.loadPreferences().then( () => {
            if(userStore.preferences.board == null){
                return;
            }
            if(props.theme != null){
                apply_board_theme();
            }else{
                userStore.applyBoardPreferences(document.getElementById("board-"+props.boardID));
            }

            if(["CW", "CCW"].includes(userStore.preferences.board.direction)){
                extra_data.direction = userStore.preferences.board.direction;
            }
            if(userStore.preferences.board.animation_duration){
                extra_data.animation_duration = parseFloat(userStore.preferences.board.animation_duration);
            }
            extra_data.show_pip_numbers = userStore.preferences.board.show_pip_numbers || props.show_pip_numbers;

            updatePosition(props.positionString);    
        });
        
    });
    updatePosition(props.positionString);
    extra_data.isMounted = true;
    
    resize_board();
});

onBeforeUnmount( () => {
    for(const arrow of extra_data.arrows){
        arrow.remove();
    }
    extra_data.arrows.length = 0;
});

var resize_timer_id = null;
window.addEventListener("resize", resize_board);

function resize_board(){
    if(resize_timer_id != null){
        return;
    }
    resize_timer_id = setTimeout(async () => {
        const board_elem = document.getElementById("board-" + props.boardID);

        if(board_elem == null){
            resize_timer_id = null;
            return;
        }
        const container_elem = board_elem.parentElement;

        const width = container_elem.offsetWidth;
        const height = container_elem.offsetHeight;
        
        const aspect_ratio = window.getComputedStyle(board_elem).getPropertyValue('aspect-ratio');
        const [aspect_w, aspect_h] = aspect_ratio.split("/").map( x => parseInt(x.trim()));
        
        const ratio_w = aspect_w / width;
        const ratio_h = aspect_h / height;
        
        if(ratio_w > ratio_h){
            board_elem.style.width = `${width}px`;
            board_elem.style.height = `${parseInt(aspect_h / ratio_w)}px`;
            board_w = width;
        }else{
            board_elem.style.height = `${height}px`;
            board_elem.style.width = `${parseInt(aspect_w / ratio_h)}px`;
            board_w = parseInt(aspect_w / ratio_h);
        }
            
        await nextTick();
        // also resize the checkers
        resize_checkers();
        resize_timer_id = null;
    }, 500);
}

async function resize_checkers(){
    await nextTick();
    const board_elem = document.getElementById("board-" + props.boardID);
    if(board_elem == null){
        return;
    }
    const checker_elements = board_elem.getElementsByClassName("stone");

    const inner_board_elements = board_elem.getElementsByClassName("board");
    const inner_board_w = inner_board_elements[0].offsetWidth;

    const checker_h = inner_board_w / 15;

    for(let checker of checker_elements){
        checker.style.height = `${parseInt(checker_h)}px`;
        checker.style.width = `${parseInt(checker_h)}px`;
    }
}

function apply_board_theme(){
    const colors = props.theme;
    const element = document.getElementById("board-" + props.boardID);
    userStore.applyBoardPreferences(element, colors);
}

function get_from_to_element(from_point_id, to_point_id){
    let from_elem, to_elem, point_from, point_to; 
    const points = state.points;

    if(typeof from_point_id == Number){
        point_from = points.find(x => x.id == from_point_id);
    }else{
        point_from = from_point_id;
        from_point_id = point_from.id;
    }
    if(typeof to_point_id == Number){
        point_to = points.find(x => x.id == to_point_id);
    }else{
        point_to = to_point_id;
        to_point_id = point_to.id;
    }

    const ghost_id = point_to.nrof_ghost_stones - (point_to.nrof_arrows_in || 0);
    const from_stone_id = point_from.nrof_stones - (point_from.nrof_arrows_out || 0);

    const board_id_pre = props.boardID + "-";

    if(point_from.nrof_stones > 4){
        from_elem = document.getElementById(board_id_pre + 
                                            "stone-"+from_point_id+"-overflow");
    }else if(point_from.nrof_ghost_stones > 0){
        from_elem = document.getElementById(board_id_pre + 
                                            "ghost-stone-"+from_point_id+
                                            "-"+point_from.nrof_ghost_stones);
    }else if(point_from.nrof_stones > 0){
        from_elem = document.getElementById(board_id_pre + 
                                            "stone-" + from_point_id + "-" + from_stone_id);
    } 
    
    if(([0,25].includes(to_point_id) && props.player_color != point_from.color)){
        to_elem =  document.getElementById(board_id_pre + "bearoff-opponent");    
    }else if(([0,25].includes(to_point_id) && props.player_color == point_from.color)){
        to_elem =  document.getElementById(board_id_pre + "bearoff-player");    
    }else if(point_to.nrof_stones > 4){
        to_elem = document.getElementById(board_id_pre + "stone-"+to_point_id+"-overflow");
    }else if(point_to.nrof_ghost_stones > 0){
        to_elem = document.getElementById(board_id_pre + "ghost-stone-"+to_point_id+"-"+ghost_id);
    }else{
        to_elem = document.getElementById(board_id_pre + "point-"+to_point_id);
    }

    return [from_elem, to_elem];
}

async function update_arrows(){
    updatePosition(props.positionString);
    for(const arrow of extra_data.arrows){
        arrow.remove();
    }
    extra_data.arrows.length = 0;
    
    const props_arrows = props.arrows.slice().sort().reverse();
    
    for(const point of state.points){
        point.nrof_ghost_stones = 0;
        point.in_degree = 0;
        point.out_degree = 0;
    }
    const drawn_arrows = extra_data.drawn_arrows.filter( (x) => x.length == 2).map( 
        (arrow) =>{
        return { 
            'start': arrow[0], 
            'end': arrow[1]
        }
    });

    draw_arrows([...props.arrows, ...drawn_arrows]);
    /*
    for(const arrow of props_arrows){
        draw_arrow(arrow.start, arrow.end);
    }
    for(const arrow of extra_data.drawn_arrows){
        if(arrow.length == 2){
            draw_arrow(arrow[0], arrow[1]);
        }
    }
    */
}

async function draw_arrows(arrows){
    const points = state.points;

    for(let arrow of arrows){
        if(arrow == null){
            continue;
        }

        const point_from = points.find(x => x.id == arrow.start);
        const point_to = points.find(x => x.id == arrow.end);

        point_from.out_degree = 1 + (point_from.out_degree || 0);
        point_to.in_degree = 1 + (point_to.in_degree || 0);
    
        point_from.nrof_ghost_stones = point_from.in_degree;
        point_to.nrof_ghost_stones = Math.max(1, point_to.in_degree - point_to.out_degree); 
    
        if(point_from.nrof_arrows_out == undefined){
            point_from.nrof_arrows_out = 0;
        }else{
            point_from.nrof_arrows_out = Math.min(point_from.nrof_stones, point_from.nrof_arrows_out + 1);
        }
        if(point_to.nrof_arrows_in == undefined){
            point_to.nrof_arrows_in = 0;
        }else{
            point_to.nrof_arrows_in = Math.max(0, point_to.nrof_arrows_in + 1);
        }
    }
    
    await nextTick();

    for(let arrow of arrows){
        const point_from = points.find(x => x.id == arrow.start);
        const point_to = points.find(x => x.id == arrow.end);
        const [from_elem, to_elem] = get_from_to_element(point_from, point_to);    

        point_from.nrof_arrows_out -= 1;
        point_to.nrof_arrows_in -= 1;
    
        let arrow_size = 6;
        const board_elem = document.getElementById("board-"+props.boardID);
        const board_rect = board_elem.getBoundingClientRect();
        const board_w = board_rect.width;

        if(board_w < 200){
            arrow_size = 2;
        }else if(board_w < 500){
            arrow_size = 4;
        }

        if(to_elem && from_elem){ 
            extra_data.arrows.push(new LeaderLine(
                LeaderLine.pointAnchor(from_elem),
                LeaderLine.pointAnchor(to_elem),
                {
                    color: 'var(--highlight-color)', 
                    size: arrow_size, 
                    path:'magnet',
                }
            ));
        }else{
            console.log("Could not draw arrow", 
                        from_elem, to_elem, point_from, point_to, arrow.start, arrow.end);
        }
    }
}

async function animate_checker(from_point_id, pips, transition_duration=0.5){
    /*
        
    */
    if(transition_duration == 0){
        return;
    }
    let from_elem, to_elem;
    
    let to_point_id = from_point_id + ( (state.color == "W")? -pips : pips);
    to_point_id = Math.min(25, Math.max(0, to_point_id));
    if(to_point_id == 25){
        to_point_id = 0;
    }
    
    const point_to = state.points.find(x => x.id == to_point_id);
    const point_from = state.points.find(x => x.id == from_point_id);

    point_to.nrof_ghost_stones += 1;

    await resize_checkers();
    await nextTick();

    [from_elem, to_elem] = get_from_to_element(point_from, point_to);    
    point_to.nrof_arrows_in = (point_to.nrof_arrows_in || 0) + 1;
    point_from.nrof_arrows_out = 1 + (point_from.nrof_arrows_out || 0);


    if(from_elem == null || to_elem == null){
        return;
    }
    
    const rect_to = to_elem.getBoundingClientRect();
    const rect_from = from_elem.getBoundingClientRect();
    
    const y_offset = parseInt(rect_to.top - rect_from.top);
    const x_offset = parseInt(rect_to.left - rect_from.left);

    const board_elem = document.getElementById("board-"+props.boardID);
    const board_rect = board_elem.getBoundingClientRect();

    animating++;

    from_elem.style.zIndex = 100;
    from_elem.style.position = "fixed";
    from_elem.style.top = `${parseInt(rect_from.top - board_rect.top + rect_from)}px`;
    from_elem.style.left = `${parseInt(rect_from.left - board_rect.left)}px`;

    from_elem.style.transition = `transform ${transition_duration}s ease 0s`;
    from_elem.style.transformOrigin = `center`;
    from_elem.style.transform = `translate(${x_offset}px, ${y_offset}px)`;
    
    await new Promise(resolve => setTimeout(resolve, transition_duration * 1000));

    from_elem.style.transition = "";
    from_elem.style.transform = "";
    from_elem.style.position = "static";
    animating--;

    redraw_board();
}

function add_arrow(from_point_id, to_point_id){
    const points = state.points;
    const point_from = points.find(x => x.id == from_point_id);
    const point_to = points.find(x => x.id == to_point_id);
}

function updatePosition(positionString, dice=null, redraw=true){
    /*
        This function is called whenever the board state is updated (externally).
    */
    state_machine.player_color = props.player_color;

    board = new BoardState(positionString);    
    extra_data.show_roll_double = show_roll_double(board);
    
    // state.points = board.points.slice();

    if(dice != null){
        state.dice = dice;
    } else if(board.dice.length > 0){
        board.dice.sort().reverse();
        if(board.dice[0] == board.dice[1]){
            state.dice = [
                {active:true, pips:board.dice[0]}, 
                {active:true, pips:board.dice[0]}, 
                {active:true, pips:board.dice[0]}, 
                {active:true, pips:board.dice[0]}
            ];
            
        }else{
            state.dice = [
                {active:true, pips:board.dice[0]}, 
                {active:true, pips:board.dice[1]}
            ]; 
        }
    }else{
        state.dice = [];
    }
    
    state.color = board.color;
    state.cube = board.cube
    state.move_id = board.move_id;
    board_ref.value = board.copy(); // TODO factor out board_ref 

    if(board.is_crawford){
        state.cube.value = -1;
    }
    
    if(redraw){
        redraw_board();
    }
}

async function redraw_board(){
    // while(animating > 0){
    //     await new Promise(resolve => setTimeout(resolve, 100));
    // }
    var permutation = [];
    state.points = board.points.slice();

    if((extra_data.direction == "CCW" && props.player_color == "B") ||
        (extra_data.direction == "CW" && props.player_color == "W")){
        // We need to reorder the points in CCW direction, so we get the following layout
        // 13 14 15 16 17 18 25 19 20 21 22 23 24
        // 12 11 10  9  8  7  0  6  5  4  3  2  1 
        permutation = [
            13, 14, 15, 16, 17, 18, 25, 19, 20, 21, 22, 23, 24,
            12, 11, 10,  9,  8,  7,  0,  6,  5,  4,  3,  2,  1,
        ];
        permutation.reverse();
    }else{
        permutation = [
            24, 23, 22, 21, 20, 19, 25, 18, 17, 16, 15, 14, 13,
             1,  2,  3,  4,  5,  6,  0,  7,  8,  9, 10, 11, 12,
        ];
        permutation.reverse();
    }
    const points = [];
    for(const index of permutation){
        points.push(state.points.find((x) => x.id == index));
    }
    state.points = points;

    if(props.player_color == "B"){
        state.points.reverse();
    }

    for(const diff in props.differences){
        if(state.points[diff]){
            state.points[diff].difference = props.differences[diff];
        }
    } 

    for(const point of state.points){
        point.nrof_ghost_stones = 0;
    }
    
    resize_checkers();
}

function clear_highlights(){
    // first clear all highlights
    for(const i in state.points){
        state.points[i].highlight = true; 
        state.points[i].highlight = false; 
    }
}

function show_roll_double(board){
    // returns True if we should show the roll/double prompt
    if(props.autoroll){
        return false;
    }
    return (board && state_machine.can_double(board));
} 

function show_take_pass(){
    // returns True if we should show the take/pass prompt
    return board_ref.value && board_ref.value.game_state == "D" && 
           board_ref.value.color != props.player_color;
} 

function show_start(){
    return props.all_players_connected && board_ref.value && 
            board_ref.value.game_state == "IW" && 
            props.player_color == "W";
}

function show_dice(){
    return board_ref.value && ["IB", "R"].includes(board_ref.value.game_state);
}

function show_undo(){
    return show_dice() && extra_data.moves.length > 0;
}

function handle_move(from_point_id, button="left"){
    state_machine.player_color = props.player_color;
    if(!props.can_do_actions){
        return false;
    }
    if(!state_machine.can_do_checkerplay(board)){
        return false;
    }

    let active_dice = state.dice.filter(d => d.active).map(d => d.pips);
    const valid_moves = board.getValidMoves(active_dice);

    if(Object.keys(valid_moves).length == 0){
        return false;
    }

    let premove = [];
    let new_state = null
    const old_state = board.toPositionString(); 
    const old_dice = state.dice.slice().map((x) => Object.assign({}, x));
    let dice;

    for(const dice_index in state.dice){
        // We loop over all the dice and use the first dice that gives us a valid move
        dice = state.dice[(button == "left")? dice_index : state.dice.length - 1 - dice_index];
    
        if(!dice.active){
            continue;
        }
        if(!(from_point_id in valid_moves) || 
           !valid_moves[from_point_id].includes(dice.pips)){
            continue;
        }
         
        new_state = board.moveStone(from_point_id, dice.pips, board.opponent[board.color]);
        if(new_state){ // moveStone returns false when no move is possible
            board = new_state;
            dice.active = false;
            premove = [from_point_id, dice.pips, board.opponent[board.color]];
            break; 
        }
    }
    // 
    if(new_state != null && new_state.toPositionString != null){
        animate_checker(
            from_point_id, 
            dice.pips,
            extra_data.animation_duration
        );
        emit("premove", premove);
        const redraw =  extra_data.animation_duration == 0;
        updatePosition(new_state.toPositionString(), state.dice, redraw);  
        extra_data.moves.push([old_state, old_dice]);

        return true;
    }
    return false;
}

function add_checker(point_id, button="left", height, event){
    if(point_id == 25 && extra_data.current_edit_color == "W"){
        point_id = 0;
        const current_stones = board.points[board.get_point_index(point_id)].nrof_stones;
        if( current_stones > 0 && event.type == "mouseup"){
            height = 0;
        }else if(event.type == "mouseup"){
            height = 1;
        }else{
            return;
        }
    }else if(point_id == 0 && extra_data.current_edit_color == "B"){
        point_id = 25;
        const current_stones = board.points[board.get_point_index(point_id)].nrof_stones;
        if( current_stones > 0 && event.type == "mouseup"){
            height = 0;
        }else if(event.type == "mouseup"){
            height = 1;
        }else{
            return;
        }
    }
    
    const point_index = board.get_point_index(point_id);
    const board_point = board.points[point_index];

    if(board_point.color != extra_data.current_edit_color){
        board_point.color = extra_data.current_edit_color;
        board_point.nrof_stones = 0
    }
    const born_off = board.get_nrof_born_off()[extra_data.current_edit_color];
    
    if(button == "left"){
        let delta = height - board_point.nrof_stones;
        if(height >= 5 && board_point.nrof_stones >= 4){
            if(event.type == "mouseup"){
                delta = 1;
            }else{
                delta = 0;
            }
        }
        if(delta > 0){
            delta = Math.min(born_off, delta);
        }
        board_point.nrof_stones += delta;
        board_point.nrof_stones = Math.max(0, board_point.nrof_stones);
    }
    if(button == "right" && born_off < 15){
        if(board_point.color == extra_data.current_edit_color){
            board_point.nrof_stones = Math.max(0, board_point.nrof_stones - 1);
        }else if(board_point.color == "W" || board_point.color == "B"){
            extra_data.current_edit_color = board_point.color;
        }
    }
    updatePosition(board.toPositionString(), state.dice);
    emit("move-end", board.toPositionString(), board)
}

async function handle_premoves(premoves){
    let new_state = new BoardState(props.positionString);
    let animation_duration = 0;
    
    for(let premove_id in premoves){
        const premove = premoves[premove_id];
        const from_point_id = premove[0];
        const pips = premove[1];
        const color = premove[2]; 
        
        if(new_state.toPositionString() == board.toPositionString()){
             animation_duration = extra_data.animation_duration;
        }

        new_state = new_state.moveStone(from_point_id, pips, color);
        
        if(!new_state){
            console.log("Premove invalid");
            return;
        }
        
        if(animation_duration > 0){
            animate_checker(from_point_id, pips, animation_duration);
        }
    }
    const redraw =  extra_data.animation_duration == 0;
    updatePosition(new_state.toPositionString(), state.dice, redraw);
}

function handle_move_highlight(point_id){
    if(!state_machine.can_do_checkerplay(board)){
        return;
    }
    let active_dice = state.dice.filter(d => d.active);
    active_dice = active_dice.map(d => d.pips);
    clear_highlights();
    
    if(active_dice.length > 0 && point_id !== null){
        const moves = board.possibleMoves(point_id, board.opponent[state.color], active_dice);
        for(const id of moves){
            if(id == -1) continue;
            state.points.find((x) => x.id == id).highlight = true; 
        }
    }
}

function handle_dice_click(){
    if(!state_machine.can_do_checkerplay(board)){
        return;
    }
    
    let active_dice = state.dice.filter(d => d.active);
    let valid_states = board.getValidStates(active_dice.map(d => d.pips));
    
    if(active_dice.length == 0 || Object.keys(valid_states).length == 0){
        // end move
        emit("move-end", props.positionString, board)
        extra_data.moves = [];
    }else{
        state.dice.push(state.dice.shift());
    }
}

function handle_roll_double(answer){
    if(!state_machine.can_double(board)){
        return;
    }
    const position_string = board.toPositionString();
    if(answer === "roll"){
        emit("move-end", position_string, "roll");
    }else{
        emit("move-end", position_string, "double");
    }   
}

function handle_resign(answer){
    const new_board = board.copy();
    new_board.color = props.player_color;
    var action = "";
    if(answer.slice(0,4) === "Game"){
        new_board.game_state = "RG";
        action = "game"; 
    }else if(answer === "Match"){
        new_board.game_state = "RM";
        action = "match"; 
    }else if(answer === "Last Game"){
        console.log("Set last game Flag");
        action = "lastgame";
    }else{
        extra_data.show_resign = false;
        return;
    }

    emit("move-end", new_board.toPositionString(), action);

    extra_data.show_resign = false;
}

function handle_flag(){
    emit("move-end", null, "flag"); 
}

function handle_take_pass(answer){
    const position_string = board.toPositionString();
    if(answer === "pass"){
        emit("move-end", position_string, "pass");
    }else{
        emit("move-end", position_string, "take");
    }   
}

function handle_start(answer){
    if(answer === "start"){
        let position_string = board.toPositionString();
        emit("move-end", position_string)
    }
}

function handle_undo(answer){
    var last_state = props.positionString;
    var dice = null;
    if(extra_data.moves.length > 0){
        const last_state_dice = extra_data.moves.pop();
        last_state = last_state_dice[0];
        dice = last_state_dice[1];
        emit("premove", "undo");
    }
    updatePosition(last_state, dice);    
}

function remove_drawn_arrows(){
    extra_data.drawn_arrows.length = 0;
    update_arrows();
}

function remove_point_highlights(){
    extra_data.point_highlights = {};
}

function create_arrow(point){
    if(extra_data.drawn_arrows.length == 0 || extra_data.drawn_arrows.at(-1).length == 2){
        extra_data.drawn_arrows.push([]);
    }
    
    const last_arrow = extra_data.drawn_arrows.at(-1);
    last_arrow.push(point.id);

}

function get_pipcount(){
    if(board){
        const pipcount = board.get_pipcount();
        if(props.player_color == "W"){
            return [pipcount["W"], pipcount["B"]];
        }else{
            return [pipcount["B"], pipcount["W"]];
        }
    }
    return [0,0];
}

function toggleChat(){
    emit('togglechat');
}   

function get_user_info(opponent=false){
    if(Object.keys(props.match_info).length == 0){
        return {};
    }
    
    if(opponent){
        if(props.player_color == "W"){
            return props.match_info.black;
        }else{
            return props.match_info.white;
        }
    }else{
        if(props.player_color == "W"){
            return props.match_info.white;
        }else{
            return props.match_info.black;
        }
    }
    return {};
}

function get_score(board){
    if(!board){
        return {};
    }
    const score = {
        "W": board.score[0], 
        "B": board.score[1]
    };
    score["match_length"] = board.match_length;
    score["crawford"] = board.is_crawford;
    score["last_game"] = props.last_game;
    
    return score; 
}

function handle_spacebar(){
    if(state_machine.can_double(board)){
        handle_roll_double("roll"); 
    }
    if(state_machine.can_do_checkerplay(board)){
        handle_dice_click();
    }
}

function handle_point_click(event){
    var moved_checker = false;
    if(props.edit_mode){
        add_checker(...event);
    }else{
        moved_checker = handle_move(...event);
    }
    if(moved_checker && gameSettingStore.settings.sound){
        checker_sound.play();
    }
}
function handle_point_drag(event){
    if(props.edit_mode){
        add_checker(...event);
    }
}

function handle_bearoff_click(color){
    if(props.edit_mode){
        extra_data.current_edit_color = color;
    }
}

</script>

<template>
<div class="case relative select-none py-[0.75em] @md:py-[2em] @container" 
    :id="'board-'+props.boardID"
    :class="{
        'py-[1em]': extra_data.show_pip_numbers,
        'flex-row-reverse': extra_data.direction == 'CCW'
     }"
    @keydown.a="remove_drawn_arrows(); remove_point_highlights();"
    @keydown.backspace="handle_undo()"
    @keydown.space="handle_spacebar();"
    tabindex=-1
>
    <div class="score-tray flex flex-col justify-between gap-y-2 mx-1 @md:mx-2">
        <div class="flex flex-col gap-y-2 grow">
            <Score 
                   :color="!board ? 'B' : board.opponent[props.player_color]"
                   :player_color="props.player_color"
                   :score="get_score(board)" 
            />
            <div class="bearoff grow overflow-hidden relative grid grid-rows-3 grid-cols-5 h-full gap-y-2 gap-x-0.5" 
                :id="boardID + '-bearoff-opponent'"
                 :class="{'outline': extra_data.current_edit_color == board.opponent[props.player_color] && props.edit_mode}"
                @click="handle_bearoff_click(board.opponent[props.player_color])"
            >
                <div class=""
                     :class="{'bg-stone-w-color': props.player_color == 'B', 
                              'bg-stone-b-color': props.player_color == 'W'}"
                     v-for="stone in board.get_nrof_born_off()[board.opponent[props.player_color]]">
                </div>
            </div>
        </div>
        <Clock v-if="Object.keys(props.clock_data).length > 0 && 
                     props.clock_data.clock_config != null &&
                     props.clock_data.clock_config.delay >= 0 && 
                     props.clock_data.clock_config.reserve >= 0"
               :player_color="props.player_color"
               :clock_color="state ? board.opponent[state.color] : 'W'"
               :clock_config="props.clock_data.clock_config" 
               :clock="props.clock_data.clock"
               @flag="handle_flag()"
        />
        <div class="flex flex-col gap-y-2 grow">
            <div class="bearoff grow overflow-hidden relative grid grid-rows-3 grid-cols-5 h-full gap-y-2 gap-x-0.5" 
                 :id="boardID + '-bearoff-player'"
                 :class="{'outline': extra_data.current_edit_color == props.player_color && props.edit_mode}"
                 @click="handle_bearoff_click(props.player_color)"
            >
                <div class=""
                     :class="{'bg-stone-w-color': props.player_color == 'W', 
                              'bg-stone-b-color': props.player_color == 'B'}"
                     v-for="stone in board.get_nrof_born_off()[props.player_color]">
                </div>
            </div>

        <div class="flex flex-col gap-y-2">
        <div class="flex gap-x-2">
            <button class='btn btn-yellow mb-2 w-6 h-6' style="padding:0.25em"
                   v-if="props.show_resign"
                   @click="extra_data.show_resign = !extra_data.show_resign"
                > 
                <FlagIcon class="text-case-color"/> 
            </button>
            <button class='btn btn-yellow mb-2 w-6 h-6' style="padding:0.25em"
                   v-if="props.show_autoroll"
                   @click="emit('autoroll')"
                > 
                <!-- This is a custom icon -->
                <AutorollIcon :class="{'fill-case-color' : !props.autoroll, 'fill-point-b-color': props.autoroll}"/> 
            </button>
        </div>
        <Score 
               :player_color="props.player_color"
               :color="props.player_color"
               :score="get_score(board)" 
        />
        </div>
        </div>
    </div>
    <div class="board">
        <Point v-for="point in state.points"
            v-bind="point"
            :boardID="props.boardID"
            :show_pip_number="extra_data.show_pip_numbers ? props.player_color : ''"
            :highlighted="extra_data.point_highlights[point.id]"
            @move-stone="handle_point_click"
            @drag-stone="handle_point_drag"
            @move-highlight="handle_move_highlight"
            @click.shift.exact.stop="create_arrow(point)"
            @click.alt.exact.stop="extra_data.point_highlights[point.id] = !(extra_data.point_highlights[point.id] || false)"
        />
        <Dice :dice="state.dice" v-if="show_dice()" class="z-10"
            :color="board.opponent[board.color]" 
            :player_color="props.player_color"
            @click="handle_dice_click"
        ></Dice>

        <Prompt :choices="['double', 'roll']" 
                :show="extra_data.show_roll_double" 
                :color="state.color"
                :position="'both'"
                @prompt-answer="handle_roll_double"
        />
        <Prompt :choices="['pass', 'take']" 
                :show="show_take_pass()" 
                :position="'both'"
                :color="state.color"
            @prompt-answer="handle_take_pass"
        />
        <Prompt :choices="['start']" :show="show_start()" :color="state.color"
            @prompt-answer="handle_start"
        />
        <Prompt :choices="['undo']" :show="show_undo()" :color="state.color"
            @prompt-answer="handle_undo"
        />
        <Prompt v-if="board.match_length > 0" 
                :choices="[`Game (${board.get_resign_value(props.player_color)})`, 'Match', 'Cancel']" 
                :show="extra_data.show_resign" 
                :color="state.color"
            @prompt-answer="handle_resign"
        />
        <Prompt v-else 
                :choices="[`Game (${board.get_resign_value(props.player_color)})`, 'Last Game', 'Cancel']" 
                :show="extra_data.show_resign" 
                :color="state.color"
            @prompt-answer="handle_resign"
        />
        <div class="board-logo"></div>

        <div class="boardmessage" 
                 :class="{'right': board_ref.dice.length > 0 && board_ref.color == props.player_color}"
            v-if="props.board_message"
        >
        {{ props.board_message }}
        </div>
        <div 
            class="pipcount top-[0em] text-xs @md:text-lg font-thin hover:font-base"
            v-if="props.show_pip_count"
        >
            {{ get_pipcount()[1] }}
        </div>
        <div class="pipcount bottom-[0em] text-xs @md:text-lg font-thin"
            v-if="props.show_pip_count"
        >
            {{ get_pipcount()[0] }}
        </div>
    </div>
    <div class="flex flex-col w-8 @md:w-10 @xl:w-16">
        <Cube 
            v-bind="state.cube" 
            :player_color="props.player_color" 
            :color_playing="board.opponent[state.color]"
        />
    </div>
</div>
</template>

<style scoped>
.case{
    position:relative;
    margin:auto; 
    display:flex;
    background-color:var(--case-color);
    color:var(--board-text-color);
    box-sizing: border-box;
    overflow: hidden;
    aspect-ratio: 14/10;
    
    touch-action: manipulation;
}
@media all and (max-aspect-ratio:12/10){
.case{
    aspect-ratio: 12/10;
    height:auto;
}
}

@media (min-aspect-ratio:12/10) and (max-aspect-ratio:14/10){
.case{
    aspect-ratio: 14/10;
}
}
@media (min-aspect-ratio:14/10) {
.case{
    aspect-ratio: 16/11;
}
}
.board{
    position:relative;
    flex-grow:10;
    display:grid;
    grid-template-columns:repeat(13,1fr);
    grid-template-rows:1fr 1fr;
    grid-auto-flow:dense;
    grid-gap:0em;
    color:var(--board-text-color);
    background: var(--field-color);
    box-sizing: content-box;
}
.boardmessage{
    position:absolute;
    right:75%;
    top:50%;
    font-size: 2.5vw;
    color: var(--board-text-color);
    background-color: var(--field-color);
    padding:0.5em;
    opacity:0.9;
    border-radius:4px;
    transform: translate(50%, -50%);
    z-index:50;
}
.boardmessage.right{
    right:25%;
}


.pipcount{
    position:absolute;
    right:50%;
    transform: translate(50%, 0);
    color: var(--board-text-color);   
    z-index:10;
    opacity: 80%;
}

.username-text{
    font-size: clamp(0.5rem, 50%, 1.0rem)
}

.board-logo{
    position:absolute;
    background-image: url("@/assets/images/logo_grey.png");
    background-color: var(--field-color);
    background-blend-mode: luminosity;
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    opacity: 20%;
    width:10%;
    height:10%;
    top:50%;
    left:20%;
    transform: translate(0, -50%);
    z-index: 1;
}

.bearoff{
    
}

</style>

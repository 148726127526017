import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { registerSW } from 'virtual:pwa-register'
import './index.css'

import Home from './components/Home.vue'
import Analysis from './components/Analysis.vue'
import Spectate from './components/Spectate.vue'
import Game from './components/Game.vue'
import UserDetail from './components/UserDetail.vue'
import UserVsDetail from './components/UserVsDetail.vue'
import LearnMenu from './components/LearnMenu.vue'
import PuzzleMenu from './components/PuzzleMenu.vue'
import Puzzle from './components/Puzzle.vue'
import DailyPuzzle from './components/DailyPuzzle.vue'
import PuzzleCommentList from './components/PuzzleCommentList.vue'
import Position from './components/Position.vue'
import PostMatch from './components/PostMatch.vue'
import MatchList from './components/MatchList.vue'
import MatchMenu from './components/MatchMenu.vue'
import DailyMatch from './components/DailyMatch.vue'
import DailyMatchList from './components/DailyMatchList.vue'
import UserMatchList from './components/UserMatchList.vue'
import AccountDetails from './components/AccountDetails.vue'
import TournamentInfo from './components/TournamentInfo.vue'
import Leaderboard from './components/Leaderboard.vue'
import Achievements from './components/Achievements.vue'
import Quiz from './components/Quiz.vue'
import AvatarBuilder from './components/AvatarBuilder.vue'
import ThemeBuilder from './components/ThemeBuilder.vue'
import BoardGif from './components/BoardGif.vue'
import BlogArticle from './components/BlogArticle.vue'
import BlogList from './components/BlogList.vue'

import Lesson from './components/Lesson.vue'
import LessonList from './components/LessonList.vue'
import LessonCreate from './components/LessonCreate.vue'
import Course from './components/Course.vue'
import CourseList from './components/CourseList.vue'

import BotGame from './components/BotGame.vue'
import BotList from './components/BotList.vue'

import Donate from './components/Donate.vue'
import DonateSuccess from './components/DonateSuccess.vue'

import AdminHome from './components/AdminHome.vue'
import AdminUserList from './components/AdminUserList.vue'
import AdminUserMail from './components/AdminUserMail.vue'
import AdminUserDetail from './components/AdminUserDetail.vue'
import AdminMatchList from './components/AdminMatchList.vue'
import AdminMatchDetail from './components/AdminMatchDetail.vue'
import AdminTournamentList from './components/AdminTournamentList.vue'
import AdminTournamentDetail from './components/AdminTournamentDetail.vue'
import AdminPuzzleList from './components/AdminPuzzleList.vue'
import AdminCommentList from './components/AdminCommentList.vue'
import AdminQuizList from './components/AdminQuizList.vue'
import AdminSolveList from './components/AdminSolveList.vue'
import AdminBotList from './components/AdminBotList.vue'
import AdminBotDetail from './components/AdminBotDetail.vue'
import AdminThemeDetail from './components/AdminThemeDetail.vue'
import AdminAnalysisList from './components/AdminAnalysisList.vue'
import AdminReportList from './components/AdminReportList.vue'
import AdminReport from './components/AdminReport.vue'
import AdminAnalyserList from './components/AdminAnalyserList.vue'
import AdminAnalyserDetail from './components/AdminAnalyserDetail.vue'

import AdminBlogList from './components/AdminBlogList.vue'
import AdminBlogDetail from './components/AdminBlogDetail.vue'

import AdminCourseCreate from './components/AdminCourseCreate.vue'
import AdminCourseList from './components/AdminCourseList.vue'
import AdminLessonList from './components/AdminLessonList.vue'

import ToS from './components/ToS.vue'
import FAQ from './components/FAQ.vue'
import Features from './components/Features.vue'
import FrontPage from './components/Frontpage.vue'
import ColorProfiles from './components/ColorProfiles.vue'


const SW_update_interval = 3*60*1000;
var SW_update_interval_id = null;

function show_update_alert(registration){
    alert("A new version of the app is available.");
    if(registration.waiting){
        registration.waiting.postMessage('SKIP_WAITING');
    }
}

const updateSW = registerSW({
    immediate : true,
    onRegisteredSW(swUrl, r) {
        console.log("Registered SW", swUrl);
        if(r){
            clearTimeout(SW_update_interval_id);
            SW_update_interval_id = setInterval(async () => {
                if (!(!r.installing && navigator)){
                    return
                }

                if (('connection' in navigator) && !navigator.onLine){
                    return
                }
                if (r.waiting) {
                    show_update_alert(r);
                }
                console.log("Updating SW");
                await r.update()

                }, 
            SW_update_interval)
        }
    }
});

if ('serviceWorker' in navigator) {
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing) {
            window.location.reload()
            refreshing = true;
        }
    });
}


const routes = [
    {path: "/", component: Home, name:"home"},
    {path: "/match/:match_id/", component: Game, name:"match"},
    {path: "/match/:match_id/post/", component: PostMatch, name:"post"},
    {path: "/match/:match_id/analysis/", component: Analysis, name:"analysis"},
    {path: "/match/:match_id/spectate/", component: Spectate, name:"spectate"},
    {path: "/match/", component: MatchMenu, name:"match-menu"},

    {path: "/daily/", component: DailyMatchList, name:"daily-list"},
    {path: "/daily/:match_id/", component: DailyMatch, name:"daily"},

    {path: "/user/:user_id/", component: UserDetail, name:"user-info"},
    {path: "/user/:user1_id/vs/:user2_id/", component: UserVsDetail, name:"user-vs-info"},
    {path: "/user/matches/", component: UserMatchList, name:"user-matches"},
    {path: "/user/account/", component: AccountDetails, name:"user-account"},
    {path: "/user/avatar/", component: AvatarBuilder, name:"user-avatar"},

    {path: "/tournament/:tournament_id/info/", component: TournamentInfo, name:"tournament-info"},

    {path: "/puzzle/", component: PuzzleMenu, name:"puzzle-menu"},
    {path: "/puzzle/comment/", component: PuzzleCommentList, name:"puzzle-comments"},
    {path: "/puzzle/:puzzle_id/", component: Puzzle, name:"puzzle", meta:{noAuthRequired: true}},
    {path: "/quiz/:quiz_id/", component: Quiz, name:"quiz"},
    {path: "/puzzle/daily/", component: DailyPuzzle, name:"daily-puzzle", meta:{noAuthRequired: true}},

    {path: "/position/:position_id/", component: Position, name:"position"},

    {path: "/bot/match/:bot_id/", component: BotGame, name:"bot-match"},
    {path: "/bot/", component: BotList, name:"bot"},

    {path: "/leaderboard/", component: Leaderboard, name:"leaderboard"},
    {path: "/achievements/", component: Achievements, name:"achievements"},

    {path: "/theme/", component: ThemeBuilder, name:"theme"},
    {path: "/export-gif/:match_id?/", component: BoardGif, name:"export-animation"},

    {path: "/learn/", component: LearnMenu, name:"learn-menu"},

    {path: "/blog/backgammon/", component: BlogList, name:"blog-list", meta:{noAuthRequired: true}},
    {path: "/blog/", redirect: {name: "blog-list"}},
    {path: "/blog/backgammon/:path*/:slug/", component: BlogArticle, name:"blog-article", meta:{noAuthRequired: true}},
    {path: "/blog/:path*/:slug/", redirect: {name: "blog-article"}},

    {path: "/lesson/", component: LessonList, name:"lesson-list"},
    {path: "/lesson/:slug/", component: Lesson, name: "lesson"},
    {path: "/lesson/edit/:lesson_id?/", component: LessonCreate, name: "lesson-create"},
    {path: "/course/", component: CourseList, name:"course-list"},
    {path: "/course/:slug/", component: Course, name: "course"},


    {path: "/features/", component: Features, name:"features", meta:{noAuthRequired: true}},
    {path: "/donate/", component: Donate, name: "donate", meta:{noAuthRequired: true}},
    {path: "/donate/success/", component: DonateSuccess, name: "donate-success", meta:{noAuthRequired: true}},

    // ADMIN URLS
    {path: "/admin/", component: AdminHome, name:"admin-home"},
    {path: "/admin/user/", component: AdminUserList, name:"admin-users"},
    {path: "/admin/user/mail/", component: AdminUserMail, name:"admin-mail"},
    {path: "/admin/user/:user_id/", component: AdminUserDetail, name:"admin-user-detail"},
    {path: "/admin/user/:user_id/solve/", component: AdminSolveList, name:"admin-user-solvelist"},
    {path: "/admin/match/", component: AdminMatchList, name:"admin-matches"},
    {path: "/admin/match/:match_id/", component: AdminMatchDetail, name:"admin-match-detail"},
    {path: "/admin/tournament/", component: AdminTournamentList, name:"admin-tournaments"},
    {path: "/admin/tournament/:tournament_id/", component: AdminTournamentDetail, name:"admin-tournament-detail"},
    {path: "/admin/puzzle/", component: AdminPuzzleList, name:"admin-puzzles"},
    {path: "/admin/puzzle/:puzzle_id/", component: Puzzle, name:"admin-puzzle-detail"},
    {path: "/admin/puzzle/comment/", component: AdminCommentList, name:"admin-commentss"},
    {path: "/admin/quiz/", component: AdminQuizList, name:"admin-quizzes"},
    {path: "/admin/bot/", component: AdminBotList, name:"admin-bots"},
    {path: "/admin/bot/:bot_id/", component: AdminBotDetail, name:"admin-bot-detail"},
    {path: "/admin/theme/:theme_id/", component: AdminThemeDetail, name:"admin-theme-detail"},
    {path: "/admin/analysis/", component: AdminAnalysisList, name:"admin-analysis"},
    {path: "/admin/analyser/", component: AdminAnalyserList, name:"admin-analysers"},
    {path: "/admin/analyser/:analyser_id/", component: AdminAnalyserDetail, name:"admin-analyser-detail"},
    {path: "/admin/report/", component: AdminReportList, name:"admin-report"},
    {path: "/admin/usage/", component: AdminReport, name:"admin-usage"},

    {path: "/admin/blog/", component: AdminBlogList, name:"admin-blog"},
    {path: "/admin/blog/:slug/", component: AdminBlogDetail, name:"admin-blog-detail"},

    {path: "/admin/lesson/", component: AdminLessonList, name:"admin-lesson-list"},
    {path: "/admin/course/", component: AdminCourseList, name:"admin-course-list"},
    {path: "/admin/course/:course_id?/", component: AdminCourseCreate, name:"admin-course-detail"},

    // STATIC URLS
    {path: "/static/tos/", component: ToS, name:"static-tos", meta:{noAuthRequired: true}},
    {path: "/static/faq/", component: FAQ, name:"static-faq", meta:{noAuthRequired: true}},
    {path: "/static/boardprofiles/", component: ColorProfiles, name:"color-profiles", meta:{noAuthRequired: true}},
    {path: "/home/", component: FrontPage, name:"frontpage", meta:{noAuthRequired: true}},
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => !(record.meta.noAuthRequired))){
        if(!localStorage.getItem("jwt")){
            next({name: "frontpage"});
        }else{
            next();
        }
    }else{
        next();
    }
});

router.afterEach((to, from) => {
    check_version();
});

const pinia = createPinia();
const app = createApp({});
const head = createHead();

pinia.use(() => ({router: router}));

const build_timestamp = "BUILD_TIMESTAMP";

async function check_version(){
    const app_server = import.meta.env.VITE_APP_SERVER;
    // console.log(app_server);
    // console.log("Local Version", build_timestamp);
    return;

    const response = await fetch(app_server + "/version/",{
        headers:{
            "Content-Type": "application/json",
        },
    });
    const version_data = await response.json();
    const latest_version = new Date(version_data.version.date);

    if( import.meta.env.PROD ){
        const latest_build = new Date(build_timestamp);
        console.log("Latest Version", latest_version);
        console.log("Local Version", latest_build);
        if(!latest_build || latest_build < latest_version){
            // messageStore.alertUser("Outdated version", 
            //     "There is a new version available, refreshing the application in 5 seconds.", {
            //         timeToLive: null,
            // });
            alert("A new version is available, reloading the app...");
            setTimeout(() => location.reload(true), 500);
        }
    }
}

window.addEventListener("beforeunload", function (e)
{
    window.sessionStorage.tabId = window.tabId;

    return null;
});

window.addEventListener("load", function (e)
{
    if (window.sessionStorage.tabId){
        window.tabId = window.sessionStorage.tabId;
        window.sessionStorage.removeItem("tabId");
    }else{
        window.tabId = Math.floor(Math.random() * 1000000);
    }

    return null;
});


app.use(router);
app.use(pinia);
app.use(head);

app.mount('#app')

import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js'; // For Base64 encoding of the secret

import { timecontrol_to_config } from '../assets/js/timecontrols.js'

import { Match } from '../assets/js/match.js'
import { BoardState } from '../assets/js/board.js'

import {useSSEStore } from '@/stores/ssestore.js'
import {useUserStore } from '@/stores/userstore.js'
import {useMessageStore } from '@/stores/messagestore.js'

const app_server = import.meta.env.VITE_APP_SERVER;

export const useDailyStore = defineStore('daily', {
    state: () => { return {
        matches: [],
        current_match_id: null,
    };},
    getters: {
        current_match(){
            if(this.current_match_id == null){
                return null;
            }
            return this.get_match(this.current_match_id);
        },
        current_state(){
            const match = this.current_match;
            if(match == null){
                return null;
            }

            return match.state;
        },
        current_clock(){
            const match = this.current_match;
            if(match == null){
                return {
                    "clock": {},
                    "clock_config": {},
                };
            }
            return {
                clock: match.clock,
                clock_config: timecontrol_to_config(match.time_control),
            }
        },
        current_color(){
            const match = this.current_match;
            return this.get_player_color(match);
        },

    },
    actions:{

    addListeners(){
        const sseStore = useSSEStore();
        const messageStore = useMessageStore();
        sseStore.connect();

        sseStore.addListener("daily move", async (data) => {
            const match = this.get_match(data.match_id);
            console.log("DAILY", data);
            if(match != null){
                match.state = data.state;
                match.provisional = data.provisional | false;
                match.nrof_provisional_steps = data.nrof_provisional_steps;
                match.is_reset = data.is_reset;
            }

            if(data.match_id != this.current_match_id){
                messageStore.alertUser("Daily Match", 
                    `A match is waiting for you.`, {
                    showNotification: true,
                    playSound: false,
                    url: {name: "daily", params:{match_id: data.match_id}},
                });   
            }
        });
        sseStore.addListener("daily provisional move", async (data) => {
            const match = this.get_match(data.match_id);
            match.provisional = true;
            match.is_reset = false;
            match.nrof_provisional_steps = data.nrof_provisional_steps;
            console.log("Provisional", data);

            for(var state of data.states){
                if(state == null){
                    break;
                }
                await new Promise(r => setTimeout(r, 2000));
                
                match.state = state;
                match.provisional = data.provisional;
                match.is_reset = false;
            }
        });

    },

    async get_user_matches(){
        const response = await fetch(app_server + "/daily/", {
            method: "GET",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const user_matches = await response.json();
        
        for(let match of user_matches.matches){
            match.score = JSON.parse(match.score);
            if(match.state == null){
                this.get_token(match.match_id);
            }
        }
        this.matches.length = 0;
        this.matches.push(...user_matches.matches);

        return user_matches.matches;
    },

    get_match(match_id){
        const match = this.matches.find((x) => x.match_id == match_id)
        return match;
    },

    get_player(match, opponent=false){
        const userStore = useUserStore();

        if(match == null){
            return null;
        }
        
        if(match.white.user_id == userStore.info.user_id){
            return (opponent) ? match.black : match.white;
        }
        else if(match.black.user_id == userStore.info.user_id){
            return (opponent) ? match.white : match.black;
        }else{
            return null;
        }
    },

    get_player_color(match){
        const userStore = useUserStore();

        if(match == null){
            return null;
        }
        
        if(match.white.user_id == userStore.info.user_id){
            return "W";
        }
        else if(match.black.user_id == userStore.info.user_id){
            return "B";
        }else{
            return null;
        }
    },

    is_active(match){
        if(this.current_match != null && match.match_id == this.current_match.match_id){
            return false;
        }
        if(match.state == null){
            return true;
        }

        const state = new BoardState(match.state);
        return state.color != this.get_player_color(match);
    },

    get_active_matches(){
        const active_matches = this.matches.filter( (match) => {
            return this.is_active(match);
        });
        
        return active_matches;
         
    },

    async get_token(match_id){
        const response = await fetch(app_server + `/daily/${match_id}/token/`, {
            method: "GET",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
        });
        
        const data = await response.json();
        const match = this.get_match(match_id)

        if(match != null){
            match.token = data.token;
        }

        if(data.commitment == null){
            await this.send_secret(match_id);
        }
    },

    async send_secret(match_id){
        if(match_id == null){
            match_id = this.current_match.match_id;
        }

        const temp_match = new Match();
        const request_data = {
            secret: CryptoJS.enc.Base64.stringify(temp_match.get_secret()),
            commitment: temp_match.get_commitment(),
        };

        const response = await fetch(app_server + 
            `/daily/${match_id}/commit/`, {
            method: "POST",
            mode: "cors",
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify(request_data),
        });
        
        const data = await response.json();
    }

    }
});


<script setup>
/*
*/
import Board from './Board.vue'
import Header from './Header.vue'
import Message from './Message.vue'
import Report from './Report.vue'
import Tooltip from './Tooltip.vue'
import PuzzleComments from './PuzzleComments.vue'

import {BoardState} from '../assets/js/board.js'
import { StateMachine } from '../assets/js/statemachine.js'
import {ref, watch, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

import {ChevronLeftIcon, ChevronRightIcon, BoltIcon, QuestionMarkCircleIcon, 
    CheckCircleIcon, CpuChipIcon, XCircleIcon, PuzzlePieceIcon, ShareIcon} from '@heroicons/vue/24/solid'

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

const route = useRoute();
const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;

const translate_double_moves = {
    "EQ ND": "No Double",
    "EQ DT": "Double, Take",
    "EQ DP": "Double, Pass",
};

const state = reactive({
    currentState: null,
    board: new BoardState(),
    player_color: "W",
});

const extra_data = reactive({
    board_message : "",
    puzzle_info: {},
    solve_info: {},
    selected: 0,
    arrows: [],
    double_solution: "ND",
    login_message: "",
    solution_histogram: {},
    advanced: false,
});

const current = reactive({
    date: null,
    daily: null,
    puzzles: {},
    equities: {},
    streak: [],
});

const clock_data = reactive({
    clock: {},
    clock_config: {},
});


onMounted(async () => {
    if(route.query.date != null){
        current.date = route.query.date;
    }else{
        current.date = new Date().toISOString().split("T")[0]
    }
    
    await get_daily(current.date);
});

function is_solved(){
    return current.equities[current.date] != null;
}

function next_daily(){
    const next_date = new Date(current.date);
    next_date.setDate(next_date.getDate() + 1);

    if( next_date > new Date() && !userStore.has_permission("daily", "editor")){
        return;
    }

    get_daily(next_date.toISOString().split("T")[0]);
}

function previous_daily(){
    const previous_date = new Date(current.date);
    previous_date.setDate(previous_date.getDate() - 1);

    get_daily(previous_date.toISOString().split("T")[0]);
}

async function copy_to_clipboard(text){
    if(text == null){
        text = window.location.href;
    }

    if(window.isSecureContext){
        await navigator.clipboard.writeText(text);
        messageStore.alertUser("Share", "Puzzle URL has been copied to your clipboard.");
    }
}

async function load_puzzle(puzzle_id){
    var puzzle_data = await get_puzzle(puzzle_id);
    extra_data.arrows.length = 0;

    if(puzzle_data.status == "error"){ // The puzzle has not yet been solved by the user
        puzzle_data = await get_puzzle_start(puzzle_id); 
        extra_data.solve_info = {};
    }else if(! is_solved()){ // we haven't solved it as a daily puzzle
        extra_data.solve_info = {};
        puzzle_data.solve = null;
    }
    extra_data.puzzle_info = puzzle_data.puzzle;
    
    if(is_solved() && puzzle_data.solve){
        extra_data.solve_info = puzzle_data.solve;
        select_move_played();
        state.board = new BoardState(state.currentState);
        state.player_color = state.board.opponent[state.board.color];
    }else{
        state.board = new BoardState(puzzle_data.puzzle.position);
        
        state.player_color = state.board.opponent[state.board.color];
        
        state.currentState = state.board.toPositionString();
        extra_data.solution_histogram = puzzle_data.solution_histogram || {};
    }

    console.log("Puzzle data:", puzzle_data);
}

async function get_daily(puzzle_date){
    current.date = puzzle_date;
    router.replace({name: "daily-puzzle", query: {"date": current.date}});

    if(current.puzzles[current.date] == null){
        await get_daily_puzzles();
    }
    current.daily = current.puzzles[current.date];
    console.log(current.daily);

    if(current.daily != null){
        load_puzzle(current.daily.puzzle_id);
    }else{
        extra_data.arrows.length = 0;
        extra_data.puzzle_info = {};
        extra_data.solve_info = {};
        extra_data.solve = null;
        state.currentState = new BoardState().toPositionString();
    }
}

async function get_daily_puzzles(){
    var header = {
        "Content-Type": "application/json",
    };
    if(localStorage.getItem("jwt")){
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt");
    }
    const response = await fetch(app_server + `/puzzle/daily/${ current.date }/`, {
        method: "GET",
        mode: "cors",
        headers: header,
    });
    const puzzle_data = await response.json();
    console.log("Daily", puzzle_data);
    
    const new_puzzles = Object.fromEntries(puzzle_data.puzzles.map( (x) => [x.date, x]));
    current.puzzles = {...current.puzzles, ...new_puzzles};

    const new_equities = Object.fromEntries(puzzle_data.equities);
    current.equities = {...current.equities, ...new_equities};

    current.streak = puzzle_data.streak || [];

    return puzzle_data;
}

async function get_puzzle(puzzle_id){
    var header = {
        "Content-Type": "application/json",
    };
    if(localStorage.getItem("jwt")){
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt");
    }
    const response = await fetch(app_server + `/puzzle/${ puzzle_id }/?rated=0`, {
        method: "GET",
        mode: "cors",
        headers: header,
    });
    const puzzle_data = await response.json();

    return puzzle_data;
}

async function get_puzzle_start(puzzle_id){
    var header = {
        "Content-Type": "application/json",
    };
    if(localStorage.getItem("jwt")){
        header["Authorization"] = "Bearer " + localStorage.getItem("jwt");
    }
    const response = await fetch(app_server + `/puzzle/${ puzzle_id }/start/`, {
        method: "POST",
        mode: "cors",
        headers: header,
    });
    const puzzle_data = await response.json();
    return puzzle_data;
}

async function get_puzzle_solve(puzzle_id, solution){
    
    if(extra_data.puzzle_info.puzzle_type == "checker"){
        const board = new BoardState(solution);
        solution = board.toPositionString();
    }


    const headers = {
        "Content-Type": "application/json",
    }
    if(!userStore.authenticated){
        extra_data.login_message = true;
    }else{
        headers["Authorization"] = "Bearer " + localStorage.getItem("jwt");
    }
    
    const response = await fetch(app_server + `/puzzle/daily/${ current.date }/solve/`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({
            "solution": solution,
        }),
        headers: headers,
    });
    const puzzle_data = await response.json();
    
    extra_data.puzzle_info = puzzle_data.puzzle;
    extra_data.solve_info = puzzle_data.solve;
    console.log(puzzle_data);
    current.equities[current.date] = puzzle_data.solve.eq_difference;
    
    // Update the userStore
    userStore.info.puzzle_rating += extra_data.solve_info.user_rating_diff;
    
    return puzzle_data;
}

async function handleMove(positionString, action=null){
    const state_machine = new StateMachine();
    state_machine.player_color = state.player_color;
    state_machine.roll_dice_callback = () => [];
    const puzzle_id = current.daily.puzzle_id;

    var solution = null;
    var new_board = state_machine.next_state(new BoardState(positionString), action);
    if(extra_data.puzzle_info.puzzle_type == "double" && (!["ND", "DT", "DP"].includes(action))){
        if(new_board.game_state == "PR"){
            solution = "ND";
        }else if(new_board.game_state == "D"){
            state.player_color = new_board.opponent[new_board.color];
            state.currentState = new_board.toPositionString();
            return; 
        }else if(new_board.game_state == "A"){
            solution = "DT";
        }else if(new_board.game_state == "P"){
            solution = "DP";
        }else{
            solution = null;
        }
    }else{
        solution = new_board.toPositionString();
    }
    console.log("SOLUTION", solution); 
    const solved = await get_puzzle_solve(puzzle_id, solution);
    if(solved){
        select_move_played();
    }
}

function select_move_played(){
    const played = extra_data.solve_info.alt_played
    if(played >= 0){
        const move_played = extra_data.puzzle_info.analysis.moves.find(x => x.alt == played);
        select_alternative(move_played);
    }else{
        var solution_board = null;
        if(extra_data.puzzle_info.puzzle_type == "checker"){
            solution_board = new BoardState(extra_data.solve_info.solution);
        }else if(extra_data.puzzle_info.puzzle_type == "double"){
            solution_board = new BoardState(extra_data.puzzle_info.position);
        }
        solution_board.dice = [];
        solution_board.update();
        state.currentState = solution_board.toPositionString();
        state.player_color = solution_board.opponent[solution_board.color];
    }
}

function select_alternative(move){
    if(move == null){
        return;
    }
    const solution_board = new BoardState(extra_data.puzzle_info.position);
    const color = solution_board.opponent[solution_board.color];
    extra_data.selected = extra_data.puzzle_info.analysis.moves.findIndex(x => x.alt == move.alt);

    solution_board.update();
    
    state.currentState = solution_board.toPositionString();
    
    extra_data.arrows = [];
    for(const arrow_ of move.move.data){
        const arrow = arrow_.slice();
        if(color == "W"){
            arrow[0] = 25 - arrow[0];
            arrow[1] = 25 - arrow[1];
        }
        extra_data.arrows.push({
            start: arrow[0],
            end: arrow[1],
        });
    }
}

function get_move(){
    const alt_played = extra_data.solve_info.alt_played;
    if(extra_data.puzzle_info.puzzle_type == "checker" && alt_played >= 0){
        const move = extra_data.puzzle_info.analysis.moves.find( x => x.alt == alt_played)
        if(move){
            return move.move.repr;
        }else{
            return "No move";
        }
    }else if(extra_data.puzzle_info.puzzle_type == "double"){
        const double_solutions = {
            "ND" : "No Double",
            "DT" : "Double, Take",
            "DP" : "Double, Pass",
        };
        return double_solutions[extra_data.solve_info.solution];
    }else{
        if(extra_data.solve_info.solution && extra_data.puzzle_info.position){
            const bs = new BoardState( extra_data.puzzle_info.position);
            return bs.getMove(new BoardState(extra_data.solve_info.solution)).text;
        }   
    }
    return "No Move" 
}

async function copy_xgid_to_clipboard(){
    const xgid = state.board.to_xgid();
     

    if(window.isSecureContext){
        await navigator.clipboard.writeText(xgid);
        messageStore.alertUser("Share", "Copied XGID to clipboard.");
    }
}

function timestamp_to_str(timestamp){
    const d = new Date(timestamp);

    return `${ d.getDate() }/${ d.getMonth() + 1 }/${ d.getFullYear() }`;
}

function in_future(){
    return Date.now() <  new Date(current.date);
}

function get_stats(){
    if(!is_solved() || current.daily == null){
        return "-";
    }
    const total = current.daily.correct + current.daily.incorrect;

    if(total == null || total == 0){
        return 100;
    }

    return (current.daily.correct * 100 / total).toFixed(0);
}

</script>

<template>
<div class="w-screen h-rest flex-col relative">
    <Header />
    <Message />
    <div class="flex flex-col w-full md:flex-row md:relative h-full gap-y-6">
        <div class="w-full md:w-2/3 md:h-rest relative">
            <Board :positionString="state.currentState" 
                   :player_color="state.player_color" 
                   :clock_data="clock_data"
                   :arrows="extra_data.arrows"
                   :show_pip_numbers="true"
                   @move-end="handleMove"
            >
            </Board>
        </div>
        <div class="px-2 md:px-4 grow md:grow-0 md:w-1/3 pb-64 overflow-y-auto">
            <div class="flex justify-center items-center gap-x-8 my-8 ">
                <ChevronLeftIcon 
                    class="w-10 h-10 hover:text-field-color cursor-pointer"
                    @click="previous_daily()"
                />
                <div class="flex flex-col gap-y-3">
                    <h1 class="text-4xl font-semibold text-center">
                        {{ timestamp_to_str(current.date) }}
                    </h1>
                    <h1 class="text-2xl text-center" v-if="in_future()">
                        Future
                    </h1>
                    <span class="flex justify-center">
                        <Tooltip :text="`This puzzle was solved correctly by ${get_stats()}% of the users`">
                            <CheckCircleIcon v-if="!is_solved()" class="text-case-color w-10 h-10 opacity-20"/>
                            <CheckCircleIcon v-else-if="current.equities[current.date] <= 0.020" class="text-field-color w-10 h-10"/>
                            <XCircleIcon v-else class="text-stone-b-color w-10 h-10"/>
                        </Tooltip>
                        <div v-if="current.streak.length > 0">
                            <Tooltip
                                :text="`This puzzle is part of your ${current.streak.length} day streak.`">
                                <BoltIcon class="w-10 h-10 text-field-color" />
                            </Tooltip>
                        </div>
                    </span>
                </div>
                <ChevronRightIcon 
                    class="w-10 h-10 hover:text-field-color cursor-pointer"
                    @click="next_daily()"
                />
            </div>
            <div v-if="current.daily == null" class="flex justify-center">
                <h1 class="text-2xl font-semibold">
                    There is no daily puzzle for this date
                </h1>
            </div>
            <div v-else-if="! is_solved()" class="flex justify-center">
                <h1 class="text-2xl font-semibold">
                    Solve the puzzle to see the explanation. 
                </h1>
            </div>
            <div v-else-if="is_solved()">
            <span class="font-thin flex gap-x-1 w-full" v-if="extra_data.puzzle_info.tags">
                <span class="rounded px-2 py-0.5 bg-field-light-color"
                    v-for="tag in extra_data.puzzle_info.tags">
                    {{ tag }}
                </span>
            </span>
            <span class="font-thin" v-else>
                No tags
            </span>
            <div v-if="extra_data.puzzle_info.puzzle_type == 'double' && !extra_data.puzzle_info.analysis">
                <div class="text-lg font-semibold mt-8">
                    {{ state.player_color == "W"? "White" : "Black" }} on roll, what is your play?
                </div>
                <div class="flex gap-x-2 items-center">
                    <input type="radio" value="ND" id="radio_ND" name="double_solutio" v-model="extra_data.double_solution"/>
                    <label for="radio_ND">No Double</label>
                </div>
                <div class="flex gap-x-2 items-center">
                    <input type="radio" value="DT" id="radio_DT" name="double_solutio" v-model="extra_data.double_solution"/>
                    <label for="radio_DT">Double, Take</label>
                </div>
                <div class="flex gap-x-2 items-center">
                    <input type="radio" value="DP" id="radio_DP" name="double_solutio" v-model="extra_data.double_solution"/>
                    <label for="radio_DP">Double, Pass</label>
                </div>
                <button class="btn btn-blue mt-4" @click="get_puzzle_solve(current.daily.puzzle_id, extra_data.double_solution)">Check Solution</button>
            </div>

            <div class="min-h-10 bg-case-light-color px-3 py-2 my-3 rounded relative"
                v-if="extra_data.puzzle_info && extra_data.puzzle_info.analysis">
                <Report :object_type="'puzzle'" :object_id="current.daily.puzzle_id"/>
                <p class="font-thin">Your play:</p>
                <div class="flex justify-between items-center font-semibold text-2xl">
                    <span>
                        {{ get_move() }}
                    </span>
                    <span class="w-10 h-10">
                        <CheckCircleIcon v-if="extra_data.solve_info.eq_difference == 0" class="text-field-color"/>
                        <XCircleIcon v-else class="text-stone-b-color"/>
                    </span>
                </div>
                <div class="mt-4 flex justify-between">
                    <span class="flex gap-x-2 items-center">
                    <router-link
                            :to="{name: 'position', params:{position_id: state.board.toPositionString()}}">
                            <CpuChipIcon class="w-6 h-6 inline-block text-field-color hover:text-field-light-color cursor-pointer" />
                    </router-link>
                     
                    <Tooltip text="Share the daily puzzle.">
                    <ShareIcon
                            title="Copy to Clipboard"
                            class="w-6 h-6 hover:text-field-color cursor-pointer" 
                            @click="copy_to_clipboard(null)"
                    />
                    </Tooltip>
                    </span>
                </div>
            </div>

            <div v-if="extra_data.puzzle_info.explanation">
                <h1>Explanation</h1>
                <p>
                    {{ extra_data.puzzle_info.explanation }}
                </p>
            </div>
            <div v-if="extra_data.login_message && false"
                class="my-8 ">
                You need to be logged in to solve this puzzle. Please go to:
                <router-link :to="{name:'frontpage'}" class="text-field-color">Login</router-link> to register or
                login.
            </div>
            <div v-if="extra_data.puzzle_info.analysis">
                <div v-if="extra_data.puzzle_info.puzzle_type == 'double'">
                    <div
                        v-for="move, i in extra_data.puzzle_info.analysis.double['cube recommendation'].actions"
                        :set="best_move = extra_data.puzzle_info.analysis.double['cube recommendation'].best"
                    >
                    <div
                        class="flex justify-between gap-y-1 px-1" 
                        :class="{'font-semibold': move[0] == best_move}"
                    >
                        
                        <span>{{ translate_double_moves[move[0]] }}</span>
                        <span class="tabular-nums">
                            {{ move[1].toFixed(3) }} ({{ (move[2]).toFixed(3).padStart(6) }})
                        </span>
                    </div>
                    </div>
                </div>
                <div v-else 
                    class="flex justify-between gap-y-1 px-1 cursor-pointer" 
                    :class="{'font-semibold': extra_data.solve_info.alt_played == move.alt, 
                             'bg-field-light-color': extra_data.selected == i}"
                    :set="best_move = extra_data.puzzle_info.analysis.moves[0]"
                    v-for="move, i in extra_data.puzzle_info.analysis.moves"
                    @click="select_alternative(move)"
                >
                    <span>{{ move.move.repr }}</span>
                    <span class="tabular-nums">
                        {{ move.EQ.toFixed(3) }} 
                        ({{ (move.EQ - best_move.EQ).toFixed(3).padStart(6) }})
                        <span v-if="extra_data.advanced">
                            [{{ extra_data.solution_histogram[move.alt] || 0 }}]
                        </span>
                    </span>
                </div>
            </div>
            <PuzzleComments 
                :puzzle_id="extra_data.puzzle_info.puzzle_id" 
                :puzzle_solved="Object.keys(extra_data.solve_info).length > 0" 
                :rated="0"
            />
        </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.game{
    height:100vh;
    height:100svh;
    width:100vw;
}
</style>
